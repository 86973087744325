import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Chevron from '@elements/Chevron'
import { strings } from '../../static/strings'
import { motion, AnimatePresence } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'

const Section = tw.section`px-5 max-w-screen-xl mx-auto`
const FlexContainer = tw.div`grid sm:grid-cols-2 lg:grid-cols-3 gap-x-20 gap-y-10 w-full flex-col items-start md:flex-row md:items-start`
const FlexItem = tw.div`flex w-full pb-10 sm:pb-7 md:pb-24 justify-center`
const StyledCard = tw.div`w-full relative flex justify-center items-start flex-col box-content`
const StyledDescription = tw(motion.p)`text-dark tracking-1 leading-[23px] text-[17px] md:leading-[30px] md:text-[20px] pt-7 pb-5`
const StyledLink = tw.a`text-primary leading-[21px] tracking-1 md:tracking-2 uppercase text-[14px] pt-5`
const TextWrap = tw(motion.div)`relative w-full mx-auto overflow-hidden`
const Flex = tw.div`px-5 flex items-center justify-end gap-4 ml-auto`
const ShowMore = tw(
	motion.div
)`uppercase relative mx-5 xl:mx-0 pt-2 pb-2 mt-4 mb-8 text-right text-sm cursor-pointer text-primary tracking-1 md:tracking-2 before:absolute before:w-24 before:bottom-full before:right-0 before:h-px before:bg-light`

const ImageWrapper = tw.div`relative flex justify-start items-center sm:h-[90px] w-full`
const StyledImage = styled(GatsbyImage)`
	img {
		${tw`max-h-[81px] w-auto h-auto max-w-[270px] `}
	}
`
const Cards = ({ item, locale }) => {
	const [isExpandable, setIsExpandable] = React.useState(false)
	const [isExpanded, setIsExpanded] = React.useState(false)
	const textRef = React.useRef()
	const lang = locale.substr(0, 2)
	const { isMobile } = useBreakpoint()

	const checkTextExpandable = React.useCallback(() => {
		const text = textRef.current
		const textHeight = text.scrollHeight
		if (textHeight > 750) setIsExpandable(true)
	}, [textRef])

	React.useEffect(() => {
		checkTextExpandable()
	}, [isExpandable])

	const variants = {
		open: {
			height: 'auto',
			transition: {
				duration: 0.5,
			},
		},
		closed: {
			height: isMobile ? 300 : 450,
			transition: {
				duration: 0.5,
			},
		},
	}

	return (
		<FlexItem>
			<StyledCard>
				<ImageWrapper>
					<a href={item.link?.url} target={item.link?.target}>
						<StyledImage image={getImage(item.image?.localFile)} alt={item.image?.altText} />
					</a>
				</ImageWrapper>
				<div>
					{item.description && (
						<TextWrap ref={textRef} animate={isExpanded || !isExpandable ? 'open' : 'closed'} variants={variants}>
							<AnimatePresence>
								<StyledDescription>{item.description}</StyledDescription>
							</AnimatePresence>
						</TextWrap>
					)}
					{isExpandable && (
						<ShowMore onClick={() => setIsExpanded(!isExpanded)}>
							<Flex>
								<Chevron state={isExpanded ? 'up' : 'down'} /> {isExpanded ? strings[lang].showLess : strings[lang].showMore}
							</Flex>
						</ShowMore>
					)}
					{item.link?.title && (
						<StyledLink href={item.link?.url} target={item.link?.target}>
							{item.link.title}
						</StyledLink>
					)}
				</div>
			</StyledCard>
		</FlexItem>
	)
}

const WpAcfPartnersBlock = ({ data, locale }) => {
	const { cards } = data.modulesPartners
	return (
		<>
			<Section className='emf-pb-spacing'>
				<FlexContainer>
					{cards.map((item, index) => (
						<Cards key={index} item={item} locale={locale} />
					))}
				</FlexContainer>
			</Section>
		</>
	)
}

export default WpAcfPartnersBlock
